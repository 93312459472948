<template>
  <div id="log">
    <cent-title title="操作日志"></cent-title>
    <el-form
      :model="logForm"
      :rules="logRules"
      ref="logForm"
      label-width="112px"
    >
      <div class="centent">
        <el-form-item label="操作者：" prop="userName">
          <el-input
            v-model.trim="logForm.userName"
            placeholder="请输入操作者"
            :maxlength="18"
          ></el-input>
        </el-form-item>
        <el-form-item label="操作描述：" prop="describe">
          <el-input
            v-model.trim="logForm.describe"
            placeholder="请输入用户操作描述"
            :maxlength="18"
          ></el-input>
        </el-form-item>
        <el-form-item label="操作地点：" prop="location">
          <el-input
            v-model.trim="logForm.location"
            placeholder="请输入用户操作地点"
            :maxlength="18"
          ></el-input>
        </el-form-item>
        <el-form-item label="操作时间：" prop="dateTime">
          <el-date-picker
            v-model="logForm.dateTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy/MM/dd"
            value-format="yyyy/MM/dd"
            :picker-options="pickerOptions"
            @change="changePicker"
          >
          </el-date-picker>
        </el-form-item>
      </div>
      <div class="clickBtn">
        <el-button class="queryBtn" @click="submitQueryForm">查询</el-button>
        <el-button @click="resetForm('logForm')">重置</el-button>
      </div>
    </el-form>
    <el-table :data="logList" border style="width: 100%">
      <el-table-column
        type="index"
        label="序号"
        width="60"
        align="center"
        :index="indexMethod"
      ></el-table-column>
      <el-table-column
        prop="username"
        label="操作者"
        width="160"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="description"
        label="操作描述"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="ip"
        label="IP地址"
        width="150"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="location"
        label="操作地点"
        width="210"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="操作时间"
        width="160"
        align="center"
      ></el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="page"
      :page-size="pageSize"
      prev-text="上一页"
      next-text="下一页"
      layout="total, prev, pager, next,jumper,slot"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import centTitle from "../../components/content/centTitle.vue";
import { request } from "../../API/request";

export default {
  name: "log",
  components: { centTitle },
  data() {
    return {
      page: 1, // 当前页数
      pageSize: 13, // 一页条数
      total: 0,
      logForm: {
        userName: "",
        describe: "",
        location: "",
        dateTime: "",
      },
      logRules: {}, // 选择框校检
      logList: [], // 表单数据

      // 限制选择日期
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  created() {
    this.getLog();
  },
  methods: {
    getLog() {
      request({
        url: "/log/get",
        method: "GET",
        params: {
          page: this.page,
          size: this.pageSize,
          location: this.logForm.location,
          username: this.logForm.userName,
          description: this.logForm.describe,
          startTime: this.logForm.dateTime[0],
          endTime: this.logForm.dateTime[1],
        },
      }).then((res) => {
        if (res.code === 200) {
          this.logList = res.data.records;
          this.total = res.data.total;
        } else {
          this.$message.error("获取操作日志失败！");
        }
      });
    },
    // 选择的操作时间
    changePicker(e) {
      this.logForm.dateTime = e;
    },
    // 查询
    submitQueryForm() {
      if (
        this.logForm.userName != "" ||
        this.logForm.describe != "" ||
        this.logForm.location != "" ||
        this.logForm.dateTime != ""
      ) {
        this.getLog();
      } else {
        this.$message.error("请先填写要查询的条件！");
      }
    },
    // 重置
    resetForm(logForm) {
      this.$refs[logForm].resetFields();
      this.page = 1;
      this.getLog();
    },
    // 改变页数对应序号
    indexMethod(index) {
      return (this.page - 1) * this.pageSize + index + 1;
    },
    // 改变页数
    handleCurrentChange(e) {
      this.page = e;
      this.getLog();
    },
  },
};
</script>

<style lang="less" scoped>
#log {
  .el-form {
    margin-top: 20px;
    .centent {
      display: flex;
      flex-wrap: wrap;
    }
    .el-form-item {
      .el-input {
        width: 280px;
      }
    }
    .clickBtn {
      width: 98%;
      display: flex;
      justify-content: flex-end;
      .queryBtn {
        background-color: var(--pink);
        margin-right: 12px;
        color: var(--white);
      }
    }
  }
  .el-table {
    margin: 20px 0 12px;
  }
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
  }
}
</style>